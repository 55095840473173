<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg" v-if="data">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Logotipo Opt-out</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <section>
          <div class="placeholder" @click="selectFile">
            <template v-if="!!placeHolderImage">
              <button class="btn">
                <i class="fas fa-file-upload"></i>
                trocar
              </button>
              <img :src="placeHolderImage">
            </template>
            <div v-else>
              Selecione uma imagem
            </div>
            <img ref="source" :class="{ 'hide': showDrop }" :src="objectUrl" style="width: 100%; max-width: 100%; max-height: 350px;">
            <drag-drop :isUnic="true" :callBackFiles="updateLogo" v-if="showDrop" />
          </div>
          <div class="field">
            <button
              class="btn btn-primary"
              :disabled="!this.placeHolderImage"
              @click="save"
            >
              Enviar
            </button>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import axios from '@/plugins/axios';
import Modal from '@/mixins/Modal';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import debounce from 'lodash/debounce';
import VueSlider from 'vue-slider-component';
import DragDrop from '@/components/DragDrop.vue';
import 'vue-slider-component/theme/antd.css';

export default {
  mixins: [Modal],
  props: ['id'],
  components: {
    DragDrop,
  },
  watch: {
    newlogo(newVal) {
      console.log('//newVal', newVal);
      this.newlogo = newVal;
      this.reset();
    },
  },
  created() {
    this._show = this.show;
    console.log('//created', this.placeHolderImage);
    this.reset();
  },
  data() {
    return {
      cropper: null,
      showDrop: true,
      isSending: false,
      placeHolderImage: null,
      newlogo: null,
      objectUrl: null,
    };
  },
  mounted() {
    console.log('//mounted', this.placeHolderImage);
    this.$root.$on('optout.modal.change', this.changeData);
    this.reset();
  },
  methods: {
    // show() {
    //   console.log('//show#2');
    //   this._show();
    // },
    selectFile() {
      console.log('selectFile>>');
      this.$refs.input.click();
    },
    reset() {
      console.log('//this', this);
      this.placeHolderImage = null;
      console.log('//this.$refs.input', this.$refs.input);
      if (this.$refs.input) this.$refs.input.value = '';
    },
    updateLogo(files) {
      this.showDrop = false;
      this.setupCropper(files[0]);
      // const input = this.$refs.input;
      // if (input.files && input.files[0]) {
      //   const reader = new FileReader();
      //   reader.onload = (e) => {
      //     this.placeHolderImage = e.target.result;
      //   };
      //   reader.readAsDataURL(input.files[0]);
      // }
    },
    setupCropper(selectedFile) {
      if (this.cropper) this.cropper.destroy();

      if (this.objectUrl) window.URL.revokeObjectURL(this.objectUrl);

      if (!selectedFile) {
        this.cropper = null;
        this.objectUrl = null;
        this.previewCropped = null;
        return;
      }

      this.objectUrl = window.URL.createObjectURL(selectedFile);
      this.$nextTick(this.setupCropperInstance);
    },
    setupCropperInstance() {
      const opts = {
        crop: this.debouncedUpdatePreview,
        viewMode: this.$store.state.crop.viewMode,
        dragMode: 'move',
        cropBoxMovable: false,
        autoCropArea: 1,
        zoom: 1,
        movable: true,
        zoomable: true,
        cropBoxResizable: false,
        zoomOnWheel: true,
        wheelZoomRatio: 0.1,
        aspectRatio: 1,
        responsive: false,
        maxCropBoxWidth: 93,
        maxCropBoxHeight: 52,
      };
      console.log(opts);
      this.cropper = new Cropper(this.$refs.source, opts);
    },
    save() {
      // this.isSending = true;
      // this.data.plain_content = null;
      // this.data.replacement_tag = null;
      // this.data.url = null;

      // const payload = new FormData();
      // payload.append('logo', new Blob([ this.placeHolderImage ]));

      // axios().post('optouts/settings/logo', payload).then((response) => {
      //   console.log('response', response);
      //   Swal.fire(
      //     'Logotipo',
      //     'Logotipo atualizado',
      //     'success',
      //   );
      //   this.reset();
      //   this.hide();
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables';

.placeholder {
  border: .15rem dashed $primary;
  margin-bottom: .5rem;
  height: 22rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  & > img {
    pointer-events: none;
    max-height: 100%;
  }

  & ~ .field input {
    display: none;
  }

  & > .btn {
    position: absolute;
    right: .5em;
    top: .5em;
    color: #fff;
    background: rgba(0,0,0,.5);
    border-radius: 10rem;
  }
}
section {
  padding: 1rem;

  .field {
    padding: .5em;
  }
}
</style>
