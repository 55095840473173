<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Novo</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="save">
          <div class="modal-body">
            <div class="form-body">
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >Emails</label
                >
                <div class="col-md-12">
                  <textarea
                    v-model="form.emails"
                    type="text"
                    class="form-control"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
              :disabled="isSending"
              type="submit"
              class="btn btn-yup-purple"
            >
              Salvar
            </button>
            <button type="button" class="btn btn-yup-purple-outline" @click="hide">
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import SmsService from '@/services/sms.service';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import Modal from '@/mixins/Modal';
import moment from 'moment';

export default {
  mixins: [Modal],
  props: ['id'],
  components: {
  },
  data() {
    return {
      form: {
      },
      isSending: false,
      options: {
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: false,
        keepInvalid: true,
      },
    };
  },
  methods: {
    save() {
      this.isSending = true;
      if (this.form.expired_at) {
        const expiredAt = moment(
          this.form.expired_at,
          'DD/MM/YYYY HH:mm',
        ).format('YYYY-MM-DD HH:mm');
        this.form.expired_at = expiredAt;

        console.log(this.form.expired_at);
        console.log(this.form);
      }
      SmsService.createOptouts({
        emails: this.form.emails,
        channel: 'email',
      }).then(
        () => {
          this.hide();
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Deny list criada com sucesso',
            type: 'success',
          });
          this.$emit('submit');
          this.isSending = false;
          this.$root.$emit('optout.modal.save');
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
            content: error.message,
            type: 'danger',
          });
          this.isSending = false;
          console.log(error.response.data.message);
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
