<template>
  <div class="card">
    <div class="card-header header_options">
      <div class="card-title header_title">OPT Outs</div>
      <form @submit.prevent="search" class="form-group col-12 col-sm-6 m-auto mb-0">
        <div class="input-group">
          <input
            class="form-control"
            type="text"
            :placeholder="$t('generic-str.lbl-recipient')"
            :v-model="searchQuery"
            ref="searchInput"
          />
          <div class="input-group-prepend">
            <button @click="search" type="button" class="btn icon_btn m-0">
              <span class="material-symbols-outlined"> search </span>
            </button>
          </div>
        </div>
      </form>
      <div class="opts_card">
        <!-- <span class="material-icons add_person"> person_add </span>
        <span class="material-icons-outlined title"> file_upload </span>
        <i class="fas fa-file-export title"></i> -->
        <!-- Criar novo -->
        <!-- <button class="btn" v-modal="{ target: 'create-optouts-modal' }">
          <span class="material-icons add_person"> add </span>
        </button> -->
        <button class="btn" :class="{'qt-loader qt-loader-mini qt-loader-right': isDownloading,}" @click="exportOptout" v-bind:disabled="isDownloading">
          <span class="material-icons-outlined title"> file_upload </span>
        </button>
        <button class="btn" @click="callOptoutLogo"
            v-modal="{
              target: 'optout-logo-modal',
              data: { newlogo },
            }">
          <!-- <span class="material-icons-outlined title"> image </span> -->
          <div class="logo-btn"><p class="mb-0">LOGO</p></div>
        </button>
        <button class="btn"
            :class="{'qt-loader qt-loader-mini qt-loader-right': isSending,}"
            @click="deleteMultiple"
            v-bind:disabled="selectedOpted.length == 0 || isSending">
            <span class="material-symbols-outlined title">delete</span>
        </button>
      </div>
    </div>
    <div v-if="!optouts.length && fetched" class="card-body">
      <LottieAnimNoData />
      <h5 class="card-title text-center">
        {{ $t('warnings.optouts.none') }}
      </h5>
      <p class="text-center">Nenhuma unsubscription foi solicitada.</p>
    </div>
    <div class="card-body" v-if="fetched">
      <div class="table-responsive" v-if="optouts.length">
        <table class="table table-infos">
          <thead>
            <tr>
              <!-- <th scope="col">
                <span class="checkbox-wrapper"
                  ><div class="control-group opt-2">
                    <label class="control control-checkbox"
                      ><input type="checkbox" />
                      <div class="control_indicator"></div
                    ></label>
                  </div>
                  {{ $tc('app.name', 1) }}</span
                >
              </th> -->
              <th class="checkbox-wrapper" scope="col">
                <div class="control-group opt-2">
                  <label class="control control-checkbox" for="customCheckTAllOptout"
                      ><input id="customCheckTAllOptout" v-model="selectAllTemplates" type="checkbox" />
                    <div class="control_indicator"></div>
                  </label>
                </div>
                Email
              </th>
              <th scope="col">
                {{$t('generic-str.date')}}
              </th>
              <th scope="col">
                {{$t('generic-str.reason')}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(optout, i) in optouts" :key="optout.id">
              <!-- <td>
                <span class="img-wrapper">
                  <div class="control-group opt-2">
                    <label class="control control-checkbox"
                      ><input type="checkbox" />
                      <div class="control_indicator"></div
                    ></label>
                  </div>
                  <div class="image">R</div>
                  <span>Rodrigo Silva</span>
                </span>
              </td> -->
              <td>
                <span class="checkbox-wrapper">
                  <div class="control-group opt-2">
                    <label class="control control-checkbox" :for="'optout-check' + i">
                      <input
                      v-model="selectedOpted"
                      :id="'optout-check' + i"
                      :value="optout" type="checkbox" />
                      <div class="control_indicator"></div
                    ></label>
                  </div>
                  <span>{{ optout.email }}</span>
                </span>
              </td>
              <td>
                <span>
                  <span>{{ optout.created_at | formatDate }}</span>
                </span>
              </td>
              <td>
                <span class="max-200" v-if="optout.reason === 'high_frequency'">
                  {{$t('opted-card-component.reasons.high-frequency.title')}}
                </span>
                <span class="max-200" v-else-if="optout.reason === 'no_interest'">
                  {{$t('opted-card-component.reasons.no-interest.title')}}
                </span>
                <span class="max-200" v-else-if="optout.reason === 'no_authorized'">
                  {{$t('opted-card-component.reasons.not-authorized.title')}}
                </span>
                <span class="max-200" v-else-if="optout.reason === 'no_time'">
                  {{$t('opted-card-component.reasons.no-time.title')}}
                </span>
                <span class="max-200" v-else-if="optout.reason === 'other'">
                  {{$tc('generic-str.other', 1)}}
                </span>
              </td>
              <td>
                <DropdownComponent variant="left" left simple>
                  <template #button>
                    <span class="material-icons-outlined title">more_vert</span>
                  </template>
                  <ButtonComponent @click="remove(optout)">
                    {{ $t('generic-str.remove') }}
                  </ButtonComponent>
                </DropdownComponent>
                <!-- <div class="dropdown">
                  <a role="button" aria-haspopup="true" aria-expanded="false"
                    ><span class="material-symbols-outlined title">
                      more_vert
                    </span></a
                  >
                  <div class="dropdown-menu" style="">
                    <a class="dropdown-item"> Edit </a
                    ><button class="dropdown-item">Remove</button>
                  </div>
                </div> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- <div v-else class="qt-block-ui relative" style="padding: 120px" /> -->
    <div class="loading min-h-300" v-else>
      <LoadingAnim />
    </div>
    <div class="card-footer">
      <div class="container-fluid">
        <div class="row">
          <pagination :totalPages="pages" v-model="currentPage" @change="fetch" />
        </div>
      </div>
    </div>
    <create-optouts-modal id="create-optouts-modal" @submit="fetchAll(1)" />
    <optouts-modal id="optouts-modal" @submit="fetchAll(1)" />
    <optout-logo-modal id="optout-logo-modal" />
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import SmsService from '@/services/sms.service';
import Pagination from '@/components/PaginationComponent.vue';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import MailService from '@/services/mail.service';
import OptoutsModal from '@/components/whatsapp/OptoutsModal.vue';
import OptoutLogoModal from '@/components/mails/OptoutLogoModal.vue';
import DropdownComponent from '@/components/DropdownComponent.vue';
import CreateOptoutsModal from '../CreateOptoutModal.vue';

export default {
  name: 'OptedCard',
  components: {
    Pagination,
    LottieAnimNoData,
    CreateOptoutsModal,
    OptoutsModal,
    OptoutLogoModal,
    LoadingAnim,
    DropdownComponent,
  },
  data() {
    return {
      fetched: false,
      isSending: false,
      searchQuery: null,
      paginate: {},
      optouts: [],
      selectedOpted: [],
      form: {
        page: 1,
        channel: 'email',
        email: null,
      },
      email: null,
      pages: 1,
      optoutSetting: {},
      newlogo: null,
      currentPage: 1,
    };
  },
  computed: {
    selectAllTemplates: {
      get() {
        if (this.optouts) {
          return this.selectedOpted.length === this.optouts.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.optouts.forEach((optout) => {
            selected.push(optout);
          });
        }

        this.selectedOpted = selected;
      },
    },
  },
  mounted() {
    this.fetch();
    this.$root.$on('optout.modal.save', this.fetch);
    MailService.getTrackingSettings({}).then(
      (response) => {
        if (response.id === null) {
          this.optoutSetting.html_content = '<p>Para não receber mais esse email clique <% aqui %></p>';
          this.optoutSetting.landing_page_html = '<p>TUDO CERTO, você foi removido da lista de emails</p>';
        } else {
          this.optoutSetting = response;
        }
      },
      (error) => {
        this.content = error;
      },
    );
  },
  methods: {
    search() {
      this.fetched = false;
      const word = this.$refs.searchInput.value;
      // console.log('Query: ', word);
      this.form.email = word ? { lk: word } : null;
      SmsService.fetchOptouts(this.form).then(
        (response) => {
          this.fetched = true;
          this.optouts = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetch(page = 1) {
      this.fetched = false;
      this.form.page = page;
      this.form.channel = 'email';
      this.currentPage = page;
      this.form.email = this.email ? { lk: this.email } : null;
      SmsService.fetchOptouts(this.form).then(
        (response) => {
          this.fetched = true;
          this.optouts = response.data;
          this.pages = response.last_page;
          console.log(response);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    remove(opted) {
      Swal.fire({
        title: `Remover email da lista de opt-outs "${opted.email}"`,
        text: 'Essa ação não poderá ser desfeita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          SmsService.deleteOptout(opted.id).then(
            () => {
              this.$modal.hide('dialog'); // Fecha o dialog após a execução do handler.
              this.fetch();
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: 'Email removido com sucesso!',
                type: 'success',
              });
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    callOptout() {
      this.$root.$emit('optout.modal.change', this.optoutSetting);
    },
    async exportOptout() {
      this.isDownloading = true;
      const name = 'optout.csv';

      MailService.exportOptout().then((blob) => {
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, name);
          this.isDownloading = false;
        } else {
          // feature detection
          // Browsers that support HTML5 download attribute
          blob = blob.slice(0, blob.size, 'application/octet-stream; charset=windows-1252');
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.setAttribute('href', url);
          link.setAttribute('download', name);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          this.isDownloading = false;
          setTimeout(() => {
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
          }, 1000);
        }
      }).catch((error) => {
        this.$toast.show({
          title: 'Optout',
          content: error,
          type: 'danger',
        });
      }).finally(() => {
        this.isDownloading = false;
      });
    },
    callOptoutLogo() {
      this.newlogo = Date.now();
      console.log('this.newlogo', this.newlogo);
    },
    fetchKeywords() {
      this.$refs.keywords.fetch(1);
    },
    selectedTemplatesChange(param) {
      this.selectedTemplates = param.selectedTemplates;
    },
    deleteMultiple() {
      Swal.fire({
        title: this.$t('mail-component.remove'),
        text: this.$t('mail-component.sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          this.selectedOpted.forEach((item) => {
            toDelete.push(item.id);
          });
          this.isSending = true;
          SmsService.deleteAllOptouts({ ids: toDelete }).then(
            () => {
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('warnings.optouts.removed-number'),
                type: 'success',
              });
              this.fetch();
              this.isSending = false;
              this.selectedOpted = [];
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.response.data.message,
                type: 'danger',
              });
              this.isSending = false;
            },
          );
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/_variables';

.table-responsive {
  min-height: 300px;
}
.btn i {
  line-height: 1;
  color: inherit;
}
.table-placeholder {
  margin-top: 25px;
}
.table-placeholder h5 {
  color: black !important;
}
.table-placeholder p {
  font-size: 13px;
}
.no-item {
  font-size: 60px;
}

td > div {
  display: inline-block;
}

// @media(min-width: 601px) {
//   .limit {
//     position: relative;

//     &::after {
//       content: "&";
//       visibility: hidden;
//     }

//     & > div {
//       position: absolute;
//       z-index: 100;
//       white-space: nowrap;
//       overflow: hidden;
//       text-overflow: ellipsis;
//       width: 98%;
//       transition: .15s all ease;
//     }

//     &:hover > div {
//       width: 200px;
//       z-index: 101;
//       padding: .5rem;
//       white-space: normal;
//       text-overflow: none;
//       background-color: #fff;
//       border: 2px solid $primary;
//       border-radius: .3rem;
//     }
//   }
// }
/* Rebranding */
.eye_icon {
  color: #bfc5cb;
}
.form-group {
  label {
    color: var(--foreground);
    font-size: 16px;
    margin-bottom: 20px;
  }
  input:-ms-input-placeholder {
    text-transform: lowercase !important;
  }
  input::-webkit-input-placeholder {
    text-transform: lowercase !important;
  }
  input::placeholder {
    text-transform: lowercase !important;
  }

  #inputPassword {
    border-right-color: transparent;
    border-right-width: 0;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}

.darkmode .rebranding .form-control,
.rebranding .form-control {
  border-right: unset !important;
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}

.header_options .input-group > input,
.header_options .input-group button {
  height: 50px;
}

.rebranding .add_person {
  cursor: pointer;
}

.rebranding .opts_card button {
  height: fit-content;
  padding: 0;
}

.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.checkbox-wrapper {
  display: flex;
  gap: 10px;
}

.table.table-infos th, .table.table-infos thead th {
  border-bottom: unset;
}

/* Logo btn */
.logo-btn > p {
  padding: 0.225rem .8rem;
  margin: auto;
  font-size: calc(0.6vw + 0.6em);
  direction: ltr;
  width: 50px;
  text-align: center;
  word-break: break-word;
  white-space: pre-line;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-word;
  word-break: break-word;
  -ms-hyphens: none;
  -moz-hyphens: none;
  -webkit-hyphens: none;
  hyphens: none;
  font-size: 14px;
  line-height: 20px;
  color: #697077 !important;
}

.logo-btn::before {
  content: '\e2c6';
  font-family: 'Material Symbols Outlined';
  font-size: 32px;
  font-weight: 300;
  color: var(--clr-yup-purple);
  opacity: 0;
  -webkit-transition: .5s;
  transition: .5s;
  position: absolute;
}

.logo-btn:hover > p {
  opacity: 0;
}

.logo-btn:hover::before {
  opacity: 1;
}
.logo-btn {
  position: relative;
  display: flex;
  justify-content: center;
  border: 1px dashed #697077;
  border-radius: 12%;
  align-items: center;
}

@media (max-width: 767.98px) {
  .card-title.header_title {
    order: -1;
    margin-bottom: 0;
  }
  .rebranding .card .opts_card {
    order: 0;
  }
  .header_title ~.form-group {
    order: 1;
  }
}
</style>
